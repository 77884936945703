const isEmpty = (str: string) => !str.trim().length;

// Slugify a string
const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

// Filter out HTML tags and non-ASCII characters, allow new lines
const sanitizedText = (text: string) => {
  const sanitized = text.replace(/(<([^>!?]+)[^>]*>)|[^ -~\n]/g, '');
  return sanitized.trim().length === 0 ? '' : sanitized;
};

export { isEmpty, sanitizedText, slugify };
